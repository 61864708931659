<template>
  <SystemPageWrapper>
    <div class="system-page__icon">
      <icon
        data="@icons/style_error_double.svg"
        class="size-56 fill-off"
      />
    </div>
    <h1 class="system-page__title heading-1-sb">
      {{ $t('Web.Error.ServerOff') }}
    </h1>
    <span class="system-page__subtitle body-1">
      {{ $t('Web.Error.ServerOffReload') }}
    </span>
  </SystemPageWrapper>
</template>

<script>

export default {
  name: 'ServerDown'
};
</script>
<style lang="scss" scoped>
@import "@/style/components/shared/page/system-page/no-access";
</style>

